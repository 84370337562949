import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { ApiResponse } from './models/api-response';
import { DashboardApiResponse } from './models/dashboard-api-response';
import { Vehicle } from './models/vehicle';
import { GenericItem } from './models/generic-item';

import { DashboardApiParameter } from './models/dashboard-api-parameter';
import { OoSApiParameter } from './models/oos-api-parameter';
import { RestApiParameter } from './models/rest-api-parameter';
import { ServicerService } from './servicer.service';
import { ChargeApiParameter } from './models/charge-api-parameter';
import { UpdateUserRideV2ApiParameter } from './models/system-update-user-ride-v2-api-parameter';
import { TaxiCooperateRequestParameter } from './models/taxi-cooperate-request-parameter';
import { TaxiCooperateRequestResponse } from './models/taxi-cooperate-request-response';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  constructor(
    private servicerService: ServicerService
  ) {
  }

  /**
   * ダッシュボードのリクエスト.
   * 
   * @param parameter DashboardApiParameter
   * @returns Observable<DashboardApiResponse>
   */
  postDashboardRequest(parameter: DashboardApiParameter): Observable<DashboardApiResponse> {
    return this.servicerService.post<string, DashboardApiResponse>(
      '/DashboardServlet',
      this.servicerService.parameterToBody(parameter));
  }

  /**
   * ダッシュボードのリクエスト.
   * 
   * @returns Observable<Vehicle>
   */
  postDashboardAllRequest(): Observable<Vehicle[]> {
    return this.postDashboardRequest({ language: environment.setting.language }).pipe(
      map(this.dashboardToVehicles));
  }

  /**
   * ダッシュボードAPIの結果から車両のリストを取得します.
   * 
   * @param response ダッシュボードAPIの結果
   * @returns 車両のリスト
   */
  private dashboardToVehicles(
    response: DashboardApiResponse
  ): Vehicle[] {
    if (!(response.result === 1 && response.dashboard)) {
      return [];
    }

    return response.dashboard.map((dashboard) => {
      return {
        vin: dashboard.vin,
        vehicleId: dashboard.vehicle_id,
        vehicleNumber: dashboard.vehicle_number,
        vehicleIconUrl: dashboard.icon_url,
        taxiStatus: dashboard.taxi_status,
        oosStatus: dashboard.servicer_oos,
        doorLock: dashboard.door_lock,
        seatbelt: dashboard.seatbelt,
        adMode: dashboard.ad_mode,
        belong: dashboard.belong,
        cooperation_status: dashboard.cooperation_status,
      };
    });
  }

  /**
   * OoS変更リクエスト.
   * 
   * @param parameter OoSApiParameter
   * @returns Observable<ApiResponse>
   */
  postServicerOoSRequest(parameter: OoSApiParameter): Observable<ApiResponse> {
    return this.servicerService.post<string, ApiResponse>(
      '/OoSServlet',
      this.servicerService.parameterToBody(parameter));
  }

  /**
   * 休憩変更リクエスト.
   * 
   * @param parameter OoSApiParameter
   * @returns Observable<ApiResponse>
   */
  postServicerRestRequest(parameter: RestApiParameter): Observable<ApiResponse> {
    return this.servicerService.post<string, ApiResponse>(
      '/RestServlet',
      this.servicerService.parameterToBody(parameter));
  }

    /**
   * 充電変更リクエスト.
   * 
   * @param parameter ChargeApiParameter
   * @returns Observable<ApiResponse>
   */
     postServicerChargeRequest(parameter: ChargeApiParameter): Observable<ApiResponse> {
      return this.servicerService.post<string, ApiResponse>(
        '/v2/vehicle/charge/chargeStart',
        this.servicerService.parameterToBody(parameter));
    }

  /**
   * 乗車人数更新APIのリクエスト.
   * 
   * @param parameter UpdateUserRideV2ApiParameter
   * @returns Observable<ApiResponse>
   */
   postUpdateUserRideRequest(parameter: UpdateUserRideV2ApiParameter): Observable<ApiResponse>{
    return this.servicerService.post<string, ApiResponse>(
      '/v2/system/updateUserRide',
      this.servicerService.parameterToBody(parameter));
  }

  /**
   * タクシー連携要求APIのリクエスト.
   * 
   * @param parameter TaxiCooperateRequestParameter
   * @returns Observable<TaxiCooperateRequestResponse>
   */
   postServicerTaxiCooperateRequest(parameter: TaxiCooperateRequestParameter): Observable<GenericItem<TaxiCooperateRequestResponse>>{
      return this.servicerService.postApi<TaxiCooperateRequestParameter, GenericItem<TaxiCooperateRequestResponse>>(
        '/v2/system/taxiCooperateRequest', parameter);
  }

}
