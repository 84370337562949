import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServicerService {
  private readonly URL = environment.setting.servicerApiUrl;

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * パラメータをbodyに変換.
   *
   * @param parameter パラメータ
   * @return body
   */
   parameterToBody<T>(parameter: T): string {
    let body = '';
    let amp = '';
    Object.keys(parameter).map((key) => {
      body += amp + key + '=' + parameter[key];
      amp = '&';
    })
    return body;
  }

  /**
   * URL取得.
   * 
   * @param path パス
   * @returns URL
   */
  private getUrl(path: string): string {
    return this.URL + path;
  }

  /**
   * post実行.
   *
   * @param path パス
   * @param parameter パラメータ
   * @return レスポンス
   */
   post<T, V>(path: string, parameter: T): Observable<V> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return this.http.post<V>(this.getUrl(path), parameter, options);
  }

  /**
   * post実行(json).
   *
   * @param path パス
   * @param parameter パラメータ
   * @return レスポンス
   */
  postApi<T, V>(path: string, parameter: T): Observable<V> {
    const options = { 
      headers: new HttpHeaders({
        'content-type': 'application/json'
      })
    };
    return this.http.post<V>(this.getUrl(path), parameter, options);
  }

  /**
   * get実行(list取得).
   *
   * @param path パス
   * @param parameter パラメータ
   * @return レスポンス
   */
  list<T, V>(path: string, parameter?: T): Observable<V> {
    const url = this.getUrl(path);
    const params = this.toHttpParams(parameter);
    const options = { headers: this.getHeader() , params: params };
    return this.http.get<V>(url, options);
  }

  /**
   * ヘッダー作成.
   * 
   * @returns new HttpHeaders
   */
  private getHeader(): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('content-type','application/json');
    return headers;
  }

  /**
   * オブジェクトをHTTPパラメータに変換する。
   *
   * @param params パラメータ
   * @return HTTPパラメータ
   */
  private toHttpParams(params: any): HttpParams {
    if (!params) {
      return null;
    }
    return Object.getOwnPropertyNames(params)
    .filter(key => params[key])
    .reduce((p, key) => p.set(key, params[key]), new HttpParams());
  }
}
