import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Geolocation, Position } from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  private cache: BehaviorSubject<Position | null> = new BehaviorSubject<Position | null>(null);
  private err: Subject<any> = new Subject();

  private watchPositionId: string;
  private errorCodeTemp = -1;

  constructor() { }

  /**
   * watchPosition を 開始します.
   */
  async start() {
    this.watchPositionId = await Geolocation.watchPosition(
      {
        enableHighAccuracy: true,
        timeout: 500,
        maximumAge: 0
      }, this.watchPositionCallback);
  }

  /**
   * watchPosition の コールバック.
   * 
   * @param position position
   * @param err エラー
   */
  private watchPositionCallback = (position: Position | null, err?: any) => {

    if(err){
      if(err.code !== this.errorCodeTemp) {
        this.errorCodeTemp = err.code;
        this.err.next(err);
      }
    } else {
      this.errorCodeTemp = -1;
    }

    this.cache.next(position);
  }

  /**
   * watchPosition を停止します.
   */
  async stop() {
    if (this.watchPositionId) {
      await Geolocation.clearWatch({ id: this.watchPositionId });
      this.watchPositionId = null;
    }
  }

  /**
   * watchPosition の Observable を取得します.
   */
  watchPosition(): Observable<Position> {
    return this.cache.asObservable().pipe(filter((position) => position !== null));
  }

  /**
   * watchPosition の Observable を取得します.
   */
  watchPositionError(): Observable<any> {
        
    return this.err.asObservable();
  }

}
